export default function LadderIcon(props: {
    active?: boolean
    white: boolean
}) {
    const active = props?.active
    return (
        <svg
            width="24"
            height="24"
            stroke={
                active
                    ? 'rgba(42, 158, 218, 1)'
                    : props.white
                      ? 'white'
                      : 'black'
            }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1570_19735)">
                <path d="M17 6.5H17.5V6V3C17.5 2.33696 17.7634 1.70107 18.2322 1.23223C18.7011 0.763392 19.337 0.5 20 0.5C20.1326 0.5 20.2598 0.552679 20.3536 0.646446C20.4473 0.740214 20.5 0.867391 20.5 1C20.5 1.13261 20.4473 1.25979 20.3536 1.35355C20.2598 1.44732 20.1326 1.5 20 1.5C19.6022 1.5 19.2206 1.65803 18.9393 1.93934C18.658 2.22065 18.5 2.60218 18.5 3V17C18.5 17.1326 18.4473 17.2598 18.3536 17.3536C18.2598 17.4473 18.1326 17.5 18 17.5C17.8674 17.5 17.7402 17.4473 17.6464 17.3536C17.5527 17.2598 17.5 17.1326 17.5 17V14V13.5H17H7H6.5V14V17C6.5 17.1326 6.44732 17.2598 6.35355 17.3536C6.25979 17.4473 6.13261 17.5 6 17.5C5.86739 17.5 5.74021 17.4473 5.64645 17.3536C5.55268 17.2598 5.5 17.1326 5.5 17V3C5.5 2.33696 5.76339 1.70107 6.23223 1.23223C6.70107 0.763392 7.33696 0.5 8 0.5C8.13261 0.5 8.25978 0.552678 8.35355 0.646447C8.44732 0.740215 8.5 0.867392 8.5 1C8.5 1.13261 8.44732 1.25979 8.35355 1.35355C8.25978 1.44732 8.13261 1.5 8 1.5C7.60218 1.5 7.22064 1.65804 6.93934 1.93934C6.65804 2.22064 6.5 2.60217 6.5 3V6V6.5H7H17ZM6.5 12V12.5H7H17H17.5V12V8V7.5H17H7H6.5V8V12Z" />
                <path d="M12.117 22.5V22.4999H11.907H11.8975L11.8975 22.4998C11.6706 22.4955 11.4436 22.4797 11.2165 22.4524C10.3699 22.3509 9.55188 22.0826 8.80974 21.6627C8.07167 21.2452 7.42328 20.6862 6.90171 20.0177C6.85243 19.9621 6.79214 19.9173 6.72462 19.8863C6.65418 19.8538 6.57755 19.837 6.5 19.837C6.42245 19.837 6.34582 19.8538 6.27538 19.8863C6.2079 19.9173 6.14763 19.9621 6.09836 20.0176C5.49554 20.7911 4.72407 21.4168 3.84269 21.847C2.95732 22.2792 1.98464 22.5025 0.999436 22.4999M12.117 22.5L18.29 19.6999C18.847 20.4178 19.5612 20.9985 20.3777 21.3972C21.1943 21.7959 22.0913 22.0021 23 21.9999M12.117 22.5L12.1276 22.4998C13.0905 22.4793 14.0367 22.2452 14.898 21.8143C15.7556 21.3852 16.5077 20.7717 17.1002 20.0177C17.1494 19.9617 17.2099 19.9166 17.2777 19.8852C17.348 19.8528 17.4245 19.8359 17.502 19.8359C17.5795 19.8359 17.656 19.8528 17.7263 19.8852C17.7938 19.9164 17.8541 19.9613 17.9032 20.0171L12.117 22.5ZM0.999436 22.4999C0.999162 22.4999 0.998888 22.4999 0.998614 22.4999L1 21.9999M0.999436 22.4999C0.999624 22.4999 0.999812 22.4999 1 22.4999V21.9999M0.999436 22.4999C0.86703 22.5001 0.740081 22.5527 0.646447 22.6464C0.552679 22.7401 0.5 22.8673 0.5 22.9999C0.5 23.1325 0.552679 23.2597 0.646447 23.3535C0.740214 23.4472 0.867391 23.4999 1 23.4999H23C23.1326 23.4999 23.2598 23.4472 23.3536 23.3535C23.4473 23.2597 23.5 23.1325 23.5 22.9999C23.5 22.8673 23.4473 22.7401 23.3536 22.6464C23.2599 22.5527 23.133 22.5001 23.0006 22.4999C23.0004 22.4999 23.0002 22.4999 23 22.4999V21.9999M1 21.9999C1.90901 22.0024 2.80648 21.7964 3.62337 21.3977C4.44025 20.9989 5.15479 20.4181 5.712 19.6999L23 21.9999M1 21.9999C0.734784 21.9999 0.48043 22.1053 0.292893 22.2928C0.105357 22.4803 0 22.7347 0 22.9999C0 23.2651 0.105357 23.5195 0.292893 23.707C0.48043 23.8946 0.734784 23.9999 1 23.9999H23C23.2652 23.9999 23.5196 23.8946 23.7071 23.707C23.8946 23.5195 24 23.2651 24 22.9999C24 22.7347 23.8946 22.4803 23.7071 22.2928C23.5196 22.1053 23.2652 21.9999 23 21.9999" />
            </g>
            <defs>
                <clipPath id="clip0_1570_19735">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
