import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import { i18nextPlugin } from 'translation-check'

import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import { hydrateRoot } from 'react-dom/client'
import i18next from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { Capacitor } from '@capacitor/core'
import { App as CapacitorApp } from '@capacitor/app'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { isNativeApp } from './root'

async function hydrate() {
    if (import.meta.env.PROD && !isNativeApp) {
        const firebaseConfig = {
            apiKey: import.meta.env.VITE_FB_API_KEY,
            authDomain: 'benefit-pools.firebaseapp.com',
            projectId: 'benefit-pools',
            storageBucket: 'benefit-pools.firebasestorage.app',
            messagingSenderId: '46938576117',
            appId: '1:46938576117:web:68b08bb0c74f7d4c8ca1bc',
            measurementId: 'G-B0W0J1QZNV',
        }
        FirebaseAnalytics.initializeFirebase(firebaseConfig)
    }

    Sentry.init({
        dsn: 'https://5a3000b2ca77b18d99c871eda52d9f84@o4507719293665280.ingest.us.sentry.io/4508545342439424',
        integrations: [Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/benefit-for-pools\.com\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    let lng = 'en'
    if (Capacitor.isNativePlatform()) {
        if (location.pathname === '/') {
            location.href = '/operator'
        }

        const info = await CapacitorApp.getInfo()

        if (info.id === 'com.benefitpools.com') {
            lng = 'en'
        } else {
            lng = 'he'
        }
    } else {
        lng = window.location.host.includes('.com') ? 'en' : 'he'
    }

    i18next
        .use(initReactI18next)
        .use(i18nextPlugin)
        .use(Backend)
        .init({
            fallbackLng: 'en',
            lng,
            backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
            interpolation: {
                escapeValue: false,
            },
        })

    startTransition(() => {
        hydrateRoot(
            document,
            <I18nextProvider i18n={i18next}>
                <StrictMode>
                    <RemixBrowser />
                </StrictMode>
            </I18nextProvider>
        )
    })
}

if (window.requestIdleCallback) {
    window.requestIdleCallback(hydrate)
} else {
    // Safari doesn't support requestIdleCallback
    // https://caniuse.com/requestidlecallback
    window.setTimeout(hydrate, 1)
}
