import { useQuery } from '@tanstack/react-query'
import { CacheKeys, client, getCache, queryClient, setCache } from './client'
import globalState, { getCurrentBranchId } from './state'
import logout from '~/utils/logout'

export function getUserDetails() {
    return queryClient.fetchQuery({
        staleTime: 120000,
        retry: 0,
        queryKey: [
            CacheKeys.getUserDetails,
            globalState.getState().accessToken,
        ],
        queryFn: async () => {
            if (!globalState.getState().accessToken) {
                return null
            }
            const userFromNetwork = await client.api.apiUsersMeRetrieve()

            if ('error' in userFromNetwork) {
                if (userFromNetwork.error.status === 401) {
                    return logout()
                }
                const userFromCache = getCache([
                    CacheKeys.getUserDetails,
                    globalState.getState().accessToken,
                ])
                if (!userFromCache || 'empty_cache' in userFromCache) {
                    return { error: 'No internet' }
                } else {
                    return userFromCache
                }
            } else {
                setCache(
                    [
                        CacheKeys.getUserDetails,
                        globalState.getState().accessToken,
                    ],
                    userFromNetwork
                )
                return userFromNetwork
            }
        },
    })
}

export function useGetUserDetails() {
    return useQuery({
        enabled: Boolean(globalState.getState().accessToken),
        retry: 0,
        staleTime: 100,
        queryKey: [
            CacheKeys.getUserDetails,
            globalState.getState().accessToken,
        ],
        queryFn: async () => {
            const userFromNetwork = await client.api.apiUsersMeRetrieve()
            if ('error' in userFromNetwork) {
                const userFromCache = getCache([
                    CacheKeys.getUserDetails,
                    globalState.getState().accessToken,
                ])
                if ('empty_cache' in userFromCache) {
                    return { error: 'No internet' }
                } else {
                    return userFromCache
                }
            } else {
                setCache(
                    [
                        CacheKeys.getUserDetails,
                        globalState.getState().accessToken,
                    ],
                    userFromNetwork
                )
                return userFromNetwork
            }
        },
    })
}

export function useGetCurrentBranch() {
    const branchId = getCurrentBranchId()
    return useQuery({
        staleTime: 100,
        queryKey: [CacheKeys.getBranch, branchId],
        enabled: Boolean(branchId),
        queryFn: async () => {
            console.log(branchId)

            const branceshFromNetwork =
                await client.api.apiOrganizationBranchRetrieve(Number(branchId))
            if ('error' in branceshFromNetwork) {
                const branchesFromCache = getCache([CacheKeys.getBranch, 'all'])
                if ('empty_cache' in branchesFromCache) {
                    return { error: 'No internet' }
                } else {
                    return branchesFromCache.results.find(
                        (result) => result.id == branchId
                    )
                }
            } else {
                return branceshFromNetwork
            }
        },
    })
}
