import { redirect } from '@remix-run/react'
import globalState from '~/lib/state'

export default function logout() {
    globalState.getState().setAccessToken('')
    localStorage.clear()

    window.location.reload()
}

//7dBdKW2SSGSDPGpa-P3NmbwlMbngVyF_A
