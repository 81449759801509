export default function ReportIcon({ active, white }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke={
                active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
            }
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.5 21.7929V17.5H19.7929L15.5 21.7929Z"
                fill="#666666"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
            />
            <path
                d="M21 17H15V23"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 17V1H3V23H15L21 17Z"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 17H7"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 12H7"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 7H7"
                stroke={
                    active ? 'rgba(42, 158, 218, 1)' : white ? 'white' : 'black'
                }
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
