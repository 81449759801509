import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = {
    disabled?: boolean
    className?: string
    onClick?: () => void
    text?: string
    children?: React.ReactNode
    id?: string
    type?: string
    isTransparent?: boolean
    customStyle?: CSSProperties
}
export default function Button({
    disabled,
    className,
    customStyle,
    onClick,
    children,
    type,
    id,
    isTransparent,
}: ButtonProps) {
    return (
        <button
            style={customStyle}
            id={id}
            type={type}
            className={twMerge(
                'flex h-14 flex-col items-center justify-center rounded-full px-4 font-semibold',
                isTransparent
                    ? 'border-2 border-darkBlue-100 text-darkBlue-100'
                    : 'bg-lightBlue-100 text-white disabled:bg-grey-disabled disabled:text-grey-dark',
                className
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}
