import { NavLink, useLocation, useNavigate, useParams } from '@remix-run/react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import globalState, { getCurrentBranchId } from '~/lib/state'
import HomeIcon from '~/media/HomeIcon'
import LogIcon from '~/media/LogIcon'
import MaskIcon from '~/media/MaskIcon'
import { useGetAllPools } from '~/queries'
import { isNativeApp } from '~/root'
import { mobileFooterHeight } from '~/utils/Consts'

import { Keyboard } from '@capacitor/keyboard'
import { useEffect, useState } from 'react'
import { Capacitor } from '@capacitor/core'

export default function OperatorFooter() {
    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            Keyboard.addListener('keyboardDidShow', () => {
                setKeyboard(true)
            })
            Keyboard.addListener('keyboardDidHide', () => {
                setKeyboard(false)
            })
        }

        return () => {
            if (Capacitor.isNativePlatform()) {
                Keyboard.removeAllListeners()
            }
        }
    }, [])
    const { t } = useTranslation()
    const [keyboard, setKeyboard] = useState(false)
    const isLandingPage = window.location.pathname === '/landing'

    const { pool_id } = useParams()
    const branch_id = getCurrentBranchId()
    const location = useLocation()
    const navLinkClass = `relative flex flex-col transition-all items-center text-12 gap-2`
    const poolsData = useGetAllPools()
    const optionalPools = poolsData?.data?.results || []
    const unsentPoolReport = localStorage.getItem(`poolLogReport${pool_id}`)
    const unsentToxicReport = localStorage.getItem(`toxicReport${branch_id}`)
    if (!isNativeApp || isLandingPage || keyboard) {
        return <></>
    }

    const auth =
        globalState.getState().accessToken &&
        !location.pathname.includes('onboarding')
    return (
        auth && (
            <>
                <footer
                    className={twMerge(
                        `h-[${mobileFooterHeight}px]`,
                        'fixed bottom-0 left-0 right-0 z-[150] flex items-start justify-between border-t border-grey-input bg-white px-3 py-4'
                    )}
                >
                    <NavLink
                        prefetch="viewport"
                        to={`/operator`}
                        className={twMerge(navLinkClass)}
                    >
                        <HomeIcon
                            active={location.pathname.includes(
                                '/operator/pools'
                            )}
                        />
                        <p
                            className={twMerge(
                                'w-20 text-center',
                                location.pathname.includes('/operator/pools') &&
                                    'text-lightBlue-100'
                            )}
                        >
                            {t`Home`}
                        </p>
                    </NavLink>
                    {optionalPools && (
                        <NavLink
                            prefetch="viewport"
                            to={`/operator/pool-reports/${pool_id || (optionalPools.length && optionalPools[0].id)}`}
                            className={navLinkClass}
                        >
                            {unsentPoolReport && (
                                <div className="absolute top-[-6px] h-3 w-3 rounded-full bg-red"></div>
                            )}
                            <LogIcon
                                active={location.pathname.includes(
                                    '/pool-reports'
                                )}
                            />
                            <p
                                className={twMerge(
                                    'w-20 text-center',
                                    location.pathname.includes(
                                        '/pool-reports'
                                    ) && 'text-lightBlue-100'
                                )}
                            >
                                {' '}
                                {t`Pool log`}
                            </p>
                        </NavLink>
                    )}
                    <NavLink
                        prefetch="viewport"
                        to={`/operator/toxic-report/yearly`}
                        className={twMerge(navLinkClass)}
                    >
                        {unsentToxicReport && (
                            <div className="absolute left-10 top-[-6px] h-3 w-3 rounded-full bg-red"></div>
                        )}
                        <MaskIcon
                            active={location.pathname.includes('/toxic-report')}
                        />
                        <p
                            className={twMerge(
                                'w-20 text-center',
                                location.pathname.includes('/toxic-report') &&
                                    'text-lightBlue-100'
                            )}
                        >
                            {t('Toxic registry')}
                        </p>
                    </NavLink>{' '}
                </footer>
            </>
        )
    )
}
