import { NavLink, useLocation } from '@remix-run/react'

import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { FaUser } from 'react-icons/fa6'
import { IoHome, IoLogOut, IoSettings } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import globalState, { getCurrentBranchId } from '~/lib/state'
import { useGetUserDetails } from '~/lib/user'
import LadderIcon from '~/media/LadderIcon'

import ReportIcon from '~/media/ReportIcon'

import whatsapp from '~/media/whatsapp-icon.svg'
import { isNativeApp } from '~/root'
import logout from '~/utils/logout'

export const contactOnWhatsapp = () =>
    window.open(
        'https://wa.me/972509863878?text=ביחס ל״בנפיט לבריכות״ - אני פונה בנושא',
        '_blank'
    )
export default function Navbar() {
    const isLandingPage = window.location.pathname === '/'
    const isIframe = window.self !== window.top
    const location = useLocation()
    const auth =
        globalState.getState().accessToken &&
        !location.pathname.includes('onboarding')

    const { t } = useTranslation()
    const user = useGetUserDetails()

    if (isNativeApp || isLandingPage || isIframe) {
        return <></>
    }

    const isManager = user.data?.manager_in_organization_branches?.find(
        (branch) => String(branch.id) === String(getCurrentBranchId())
    )

    if (auth)
        return (
            <>
                <div className="md:ps-16"></div>

                <footer
                    className={twMerge(
                        'fixed bottom-0 left-0 right-0 z-[100] flex w-screen items-center justify-around gap-0 border-t border-grey-input bg-navbarBlue py-3 md:top-0 md:h-full md:w-auto md:flex-col md:justify-start md:gap-10 md:px-2 md:pt-8 md:shadow-xl',
                        i18next.language === 'he'
                            ? 'md:left-auto'
                            : 'md:right-auto'
                    )}
                >
                    <NavLink
                        prefetch="render"
                        className={twMerge(
                            'flex h-12 w-2/12 flex-col items-center justify-between text-12'
                        )}
                        to="/dashboard?search=my_pools"
                    >
                        {' '}
                        <IoHome
                            color={
                                location.pathname === '/dashboard'
                                    ? 'rgba(42, 158, 218, 1)'
                                    : 'white'
                            }
                            className="h-6 w-6"
                        />
                        <p
                            className={twMerge(
                                location.pathname === '/dashboard'
                                    ? 'text-lightBlue-100'
                                    : 'text-white'
                            )}
                        >
                            {t('Home')}
                        </p>
                    </NavLink>
                    <NavLink
                        prefetch="render"
                        className="flex h-12 w-2/12 flex-col items-center justify-between text-12"
                        to="/my_pools"
                        data-testid="pools-link"
                    >
                        {' '}
                        <LadderIcon
                            white
                            active={
                                location.pathname.includes('pool') &&
                                !location.pathname.includes('operator')
                            }
                        />
                        <p
                            className={twMerge(
                                location.pathname.includes('pool') &&
                                    !location.pathname.includes('operator')
                                    ? 'text-lightBlue-100'
                                    : 'text-white'
                            )}
                        >
                            {t('Pools')}
                        </p>
                    </NavLink>
                    <NavLink
                        prefetch="render"
                        className={
                            'flex h-12 w-2/12 flex-col items-center justify-between break-words text-center text-12'
                        }
                        to="/pool_operators"
                    >
                        {' '}
                        <FaUser
                            color={
                                location.pathname.includes('pool_operator')
                                    ? 'rgba(42, 158, 218, 1)'
                                    : 'white'
                            }
                            className="h-6 w-6"
                        />
                        <p
                            className={twMerge(
                                location.pathname.includes('pool_operator')
                                    ? 'text-lightBlue-100'
                                    : 'text-white'
                            )}
                        >
                            {t('Operators')}
                        </p>
                    </NavLink>{' '}
                    <NavLink
                        prefetch="render"
                        to="/reports/logs/search"
                        className="flex h-12 w-2/12 flex-col items-center justify-between text-12"
                    >
                        {' '}
                        <ReportIcon
                            white
                            active={location.pathname.includes('reports')}
                        />
                        <p
                            className={twMerge(
                                location.pathname.includes('reports')
                                    ? 'text-lightBlue-100'
                                    : 'text-white'
                            )}
                        >
                            {t('Reports')}
                        </p>
                    </NavLink>{' '}
                    {isManager && (
                        <NavLink
                            prefetch="render"
                            to="/profile"
                            className="flex h-12 w-2/12 flex-col items-center justify-between text-12"
                        >
                            <IoSettings
                                className="h-6 w-6"
                                color={
                                    location.pathname === '/profile' ||
                                    location.pathname === '/payment'
                                        ? 'rgba(42, 158, 218, 1)'
                                        : 'white'
                                }
                            />
                            <p
                                className={twMerge(
                                    location.pathname === '/profile' ||
                                        location.pathname === '/payment'
                                        ? 'text-lightBlue-100'
                                        : 'text-white'
                                )}
                            >
                                {t('Settings')}
                            </p>
                        </NavLink>
                    )}
                    <button
                        onClick={contactOnWhatsapp}
                        className="mx-auto hidden h-10 w-10 flex-col items-center justify-center gap-2 px-1 text-12 text-white md:mt-14 md:flex"
                    >
                        <img src={whatsapp} alt="whatsapp icon" />
                        {t('Whatsapp support')}
                    </button>
                    <button
                        onClick={logout}
                        className="flex h-12 w-12 flex-col items-center justify-around gap-2"
                    >
                        <IoLogOut fill="white" className="h-6 w-6" />
                        <p className="text-xs text-white">{t('logout')}</p>
                    </button>
                </footer>
            </>
        )
}
