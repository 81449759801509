export default function MaskIcon(props: { active?: boolean }) {
    const active = props?.active
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.88623 11.4492L8.14923 16.9742"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.8511 16.9742L18.1141 11.4492"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.7059 6.00043C18.274 4.55755 17.3883 3.29245 16.1802 2.39303C14.9721 1.4936 13.5061 1.00781 11.9999 1.00781C10.4938 1.00781 9.02783 1.4936 7.81973 2.39303C6.61164 3.29245 5.72586 4.55755 5.29395 6.00043"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.79199 19.0215L6.41999 22.6745"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.2081 19.0215L17.5801 22.6745"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 22C14.2091 22 16 20.2091 16 18C16 15.7909 14.2091 14 12 14C9.79086 14 8 15.7909 8 18C8 20.2091 9.79086 22 12 22Z"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.658 6.882C20.475 6.61049 20.228 6.38808 19.939 6.23429C19.6499 6.08051 19.3274 6.00005 19 6H5C4.46957 6 3.96086 6.21071 3.58579 6.58579C3.21071 6.96086 3 7.46957 3 8V9.6C3.00002 9.90183 3.06836 10.1997 3.19989 10.4714C3.33142 10.7431 3.52274 10.9814 3.75949 11.1687C3.99625 11.3559 4.27231 11.4871 4.56698 11.5524C4.86165 11.6178 5.1673 11.6156 5.461 11.546L12 10L18.539 11.549C18.6916 11.5858 18.848 11.6042 19.005 11.604C19.3015 11.6032 19.5941 11.5365 19.8617 11.4087C20.1293 11.2809 20.365 11.0952 20.552 10.865C20.844 10.5083 21.0024 10.0609 21 9.6V8C21.0006 7.60148 20.8815 7.21196 20.658 6.882Z"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 6V10"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
