export default function HomeIcon(props: { active?: boolean }) {
    const active = props?.active
    return (
        <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 1L1 9V22H8V15H14V22H21V9L11 1Z"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
