import '~/components/drop.css'
import Spacer from './Spacer'
import whiteLogo from '../media/white-logo.svg'
import dropSplash from '../media/animatedDrop.gif'
import { motion, AnimatePresence } from 'framer-motion'
import { isNativeApp } from '~/root'
export default function Splash({ show }: { show: boolean }) {
    return (
        <AnimatePresence>
            {isNativeApp && show && (
                <motion.div
                    exit={{ opacity: 0 }}
                    className="fix z-[500] flex h-screen w-screen flex-col items-center justify-center bg-darkBlue-100"
                >
                    <div className="w-full">
                        <img
                            className="mx-auto mt-10 h-20 w-fit"
                            src={whiteLogo}
                            alt="benefit logo"
                        />
                    </div>
                    <img src={dropSplash} alt="a drop splashes on the water" />

                    <Spacer height={'80vh'} />
                </motion.div>
            )}
        </AnimatePresence>
    )
}
