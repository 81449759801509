export default function LogIcon(props: { active?: boolean }) {
    const active = props?.active
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6 23H2V1H20V6"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14 21L10 22L11 18L19 10L22 13L14 21Z"
                stroke={active ? '#4FC4F4' : '#666666'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
