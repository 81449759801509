import * as Dialog from '@radix-ui/react-dialog'
import { useEffect, useState } from 'react'
import warning from '~/media/warning-red.svg'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { differenceInDays, format, parseISO } from 'date-fns'
import { useGetCurrentBranch, useGetUserDetails } from '~/lib/user'
import globalState from '~/lib/state'
import { twMerge } from 'tailwind-merge'
import clock from '~/media/clock.svg'
import { OrganizationBranch } from '~/generated/api'
import { useLocation, useNavigate } from '@remix-run/react'
import { BsExclamationCircleFill } from 'react-icons/bs'

import { IoClose } from 'react-icons/io5'
import { isNativeApp } from '~/root'
import { motion } from 'framer-motion'

export default function SubscriptionWarning() {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()
    const today = String(format(new Date(), 'dd/MM/yyyy'))

    const location = useLocation()

    useEffect(() => {
        if (
            localStorage.getItem(
                `show-subscription-modal-${today}-${branch?.name}`
            )
        ) {
            setOpen(false)
        }
    }, [today])

    const { isLoading } = useGetCurrentBranch()
    const { daysUntilEnd, branch, trialMode, endDate } =
        useSubscriptionHandling()
    const navigate = useNavigate()
    const user = useGetUserDetails()

    const isManager = branch?.managers?.find((m) => m.id === user.data?.id)
    const notToShowOnThisPages =
        location.pathname.includes('/auth') ||
        location.pathname.includes('/loading') ||
        location.pathname === '/' ||
        location.pathname.includes('/onboarding') ||
        window.self !== window.top
    if (notToShowOnThisPages) {
        return <></>
    }
    if (isLoading) {
        return <></>
    }
    if (isNativeApp) {
        return <></>
    }
    if (trialMode || daysUntilEnd() < 30) {
        return (
            <Dialog.Root open={open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Dialog.Overlay className={twMerge('DialogOverlay')} />
                    <Dialog.Content
                        aria-describedby={undefined}
                        className="fixed left-2/4 top-1/3 flex w-[90vw] -translate-x-2/4 -translate-y-1/3 flex-col items-center"
                    >
                        <Dialog.Title className="DialogTitle"></Dialog.Title>

                        <div className="flex w-10/12 max-w-96 flex-col items-center gap-8 rounded-lg bg-white p-8">
                            <img src={clock} alt="clock" />
                            <h1 className="text-center text-24 font-bold">
                                {daysUntilEnd() <= 0
                                    ? t`Your subscription has ended.`
                                    : trialMode
                                      ? t`Your trial will end soon`
                                      : t`Your subscription will end soon`}
                            </h1>
                            {daysUntilEnd() > 0 ? (
                                <p className="text-center text-16">
                                    {trialMode && (
                                        <>
                                            30 ימי הניסיון שלך עומדים להסתיים
                                            עוד {daysUntilEnd()} ימים. הגישה שלך
                                            למערכת של Benefit-for-pool תסתיים
                                            סופית ב{'-'}
                                            {String(
                                                format(
                                                    new Date(endDate()),
                                                    'dd/MM/yyyy'
                                                )
                                            )}
                                        </>
                                    )}
                                    {!trialMode && (
                                        <>
                                            המנוי השנתי שלך עומד להסתיים עוד{' '}
                                            {daysUntilEnd()} ימים. הגישה שלך
                                            למערכת של Benefit-for-pool תסתיים
                                            סופית ב{'-'}
                                            {String(
                                                format(
                                                    new Date(endDate()),
                                                    'dd/MM/yyyy'
                                                )
                                            )}
                                        </>
                                    )}
                                </p>
                            ) : (
                                <p className="text-center text-20">
                                    <span>{t`You can only renew you account`}</span>
                                </p>
                            )}
                            {isManager ? (
                                <Button
                                    onClick={() => {
                                        localStorage.setItem(
                                            `show-subscription-modal-${today}-${branch?.name}`,
                                            'true'
                                        )
                                        setOpen(false)
                                        navigate('/payment')
                                    }}
                                    className={twMerge('w-36 bg-darkGreen-90')}
                                >{t`Registration`}</Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        localStorage.setItem(
                                            `show-subscription-modal-${today}-${branch?.name}`,
                                            'true'
                                        )
                                        setOpen(false)
                                    }}
                                    className={twMerge('w-36 bg-darkGreen-90')}
                                >{t`Continue`}</Button>
                            )}
                        </div>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        )
    } else {
        return <></>
    }
}

export function useSubscriptionHandling() {
    const { data, isLoading } = useGetCurrentBranch()
    const branch: OrganizationBranch = data
    const locked = branch?.active_subscription?.locked

    const trialMode = branch?.is_trial_mode && !branch?.active_subscription?.id
    const daysUntilEnd = () => {
        if (isLoading) {
            return 1
        }
        if (trialMode) {
            return branch?.days_to_trial_end
        } else {
            return (
                branch?.active_subscription?.days_until_end_of_subscription || 0
            )
        }
    }
    const endDate = () => {
        if (trialMode) {
            return branch.trial_end_date
        } else {
            return branch?.active_subscription?.end_of_subscription_date
        }
    }
    return { daysUntilEnd, branch, trialMode, locked, endDate }
}

export function useRedirectIfLocked() {
    const { daysUntilEnd } = useSubscriptionHandling()
    const location = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (daysUntilEnd() <= 0) {
            if (!isNativeApp) {
                navigate('/dashboard')
            }
        }
    }, [location.pathname])
}

export function SubscriptionToast() {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()

    const { data, isLoading } = useGetCurrentBranch()
    const location = useLocation()
    const { daysUntilEnd, trialMode, endDate, branch } =
        useSubscriptionHandling(data)
    const user = useGetUserDetails()

    const notToShowOnThisPages =
        location.pathname.includes('/auth') ||
        location.pathname.includes('/loading') ||
        location.pathname === '/' ||
        location.pathname.includes('/onboarding') ||
        window.self !== window.top

    if (notToShowOnThisPages) {
        return <></>
    }
    if (isNativeApp) {
        return <></>
    }
    if (data?.error) {
        return <></>
    }

    if (isLoading) {
        return <></>
    }

    const isManager = branch?.managers?.find((m) => m.id === user.data?.id)

    if ((trialMode || daysUntilEnd() < 30) && open) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={twMerge(
                    'fixed left-1 right-1 top-4 z-50 flex items-center gap-4 rounded-md p-2 px-4 text-white shadow-md lg:left-4 lg:right-24',
                    daysUntilEnd() <= 0 ? 'bg-red' : 'bg-darkBlue-100'
                )}
            >
                <BsExclamationCircleFill className="hidden lg:block" />
                {daysUntilEnd() > 0 ? (
                    <p className="text-center text-16">
                        {trialMode && (
                            <>
                                30 ימי הניסיון שלך עומדים להסתיים עוד{' '}
                                {daysUntilEnd()} ימים.
                                <span className="hidden lg:inline">
                                    {' '}
                                    הגישה שלך למערכת של Benefit-for-pool תסתיים
                                    סופית ב{'-'}
                                    {String(
                                        format(
                                            new Date(endDate()),
                                            'dd/MM/yyyy'
                                        )
                                    )}{' '}
                                </span>
                            </>
                        )}
                        {!trialMode && (
                            <>
                                המנוי השנתי שלך עומד להסתיים עוד{' '}
                                {daysUntilEnd()} ימים.
                                <span className="hidden lg:inline">
                                    {' '}
                                    הגישה שלך למערכת של Benefit-for-pool תסתיים
                                    סופית ב{'-'}
                                    {String(
                                        format(
                                            new Date(endDate()),
                                            'dd/MM/yyyy'
                                        )
                                    )}{' '}
                                </span>
                            </>
                        )}
                    </p>
                ) : (
                    <p className="text-center text-20">
                        <span>
                            {trialMode
                                ? t`Your trial has ended.`
                                : t`Your subscription has ended.`}{' '}
                            -{' '}
                        </span>
                        {isManager && (
                            <span>{t`You can only renew you account`}</span>
                        )}
                    </p>
                )}

                <button
                    data-testid="closeTrialToast"
                    className="absolute left-0 px-2"
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    {' '}
                    <IoClose />
                </button>
            </motion.div>
        )
    }
}
